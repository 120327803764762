import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { motion } from "framer-motion"
import vars from "../../styles/vars"
import animationVariants from "../../animations/hero-banner-animations"
import { getRandomInt } from "./../../utils/global-util"
import Breadcrumb from "../ui/breadcrumb"

const HeroBanner = props => {
  const {
    title,
    content,
    data,
    padBottomHeroBanner: padBottom,
    currentPageSlug,
    breadcrumb,
  } = props

  const backgroundImage = data.length > 0 && data[0].mod__hero_banner_image

  const [randomGradient, setRandomGradient] = useState([])
  const isHomePage = currentPageSlug === "home" ? true : false

  useEffect(() => {
    const gradients = vars.gradientColourGroup
    setRandomGradient(
      isHomePage ? gradients[0] : gradients[getRandomInt(gradients.length)]
    )
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  // For more info on the above eslint comment see: https://stackoverflow.com/a/60327893

  return (
    <StyledHeroBanner
      backgroundImage={backgroundImage}
      randomGradient={randomGradient}
      style={{
        paddingBottom: padBottom ? 150 : 20,
        textAlign: isHomePage ? "center" : "left",
      }}
      isHomePage={isHomePage}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className={isHomePage ? "col-12" : "col"}>
            <motion.div
              key="hero-banner-motion-div"
              variants={animationVariants}
            >
              <h1>{title}</h1>
              <div dangerouslySetInnerHTML={{ __html: content }} />
              <Breadcrumb data={breadcrumb} />
            </motion.div>
          </div>
        </div>
      </div>
    </StyledHeroBanner>
  )
}

export default HeroBanner

const StyledHeroBanner = styled.div`
  position: relative;
  color: ${vars.white};
  padding: 40px 0 20px 0;
  margin-bottom: 30px;
  overflow: hidden;

  .container {
    position: relative;
    z-index: 10;
  }

  background: ${props =>
    props.backgroundImage
      ? `url(${props.backgroundImage})`
      : `radial-gradient(circle, ${props.randomGradient})`};

  background-size: ${props => (props.backgroundImage ? "cover" : "800% 800%")};

  animation: ${props =>
    props.backgroundImage ? "" : "GradientAnimation 43s ease infinite"};

  h1 {
    ${props => props.isHomePage && "margin-bottom: 1rem;"}
    margin-top: 0;
    font-size: ${props => (props.isHomePage ? "7rem" : "4rem")};
    line-height: ${props => (props.isHomePage ? "7.5rem" : "4.5rem")};

    @media (max-width: ${vars.screen_lg_max}) {
      font-size: ${props => (props.isHomePage ? "5rem" : "4rem")};
      line-height: ${props => (props.isHomePage ? "5.5rem" : "4.5rem")};
    }

    @media (max-width: ${vars.screen_md_max}) {
      font-size: 3.5rem;
      line-height: 4rem;
    }

    @media (max-width: ${vars.screen_sm_max}) {
      font-size: 3.5rem;
      line-height: 4rem;
    }

    @media (max-width: ${vars.screen_xs_max}) {
      font-size: 2.1rem;
      line-height: 2.6rem;
    }
  }

  p {
    font-weight: 700;
    font-size: 1.1rem;
  }

  :after {
    content: "";
    display: ${props => (props.backgroundImage ? "block" : "none")};
    opacity: 0.8;
    background: ${props =>
      props.backgroundImage &&
      `radial-gradient(circle, ${props.randomGradient})`};
    background-size: ${props => props.backgroundImage && "800% 800%"};
    ${props =>
      props.backgroundImage &&
      "animation: GradientAnimation 43s ease infinite;"}
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  @keyframes GradientAnimation {
    0% {
      background-position: 4% 0%;
    }
    50% {
      background-position: 97% 100%;
    }
    100% {
      background-position: 4% 0%;
    }
  }
`
