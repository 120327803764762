import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BlogItem from "../common/blog-item"

// The below line of code is used for limiting the graphql query to 7 posts.
//allWordpressPost(sort: { fields: wordpress_id, order: DESC }, limit: 7) {

const BlogList = () => {
  const data = useStaticQuery(graphql`
    query BlogList {
      allWordpressPost(sort: { fields: wordpress_id, order: DESC }) {
        nodes {
          id
          wordpress_id
          slug
          path
          title
          excerpt
          date(formatString: "DD/MM/YYYY")
          acf {
            modules_post {
              __typename
              mod__hero_banner_image
            }
          }
        }
      }
    }
  `)

  const { nodes: blogList } = data.allWordpressPost

  return (
    <div className="container">
      <div className="row">
        {blogList.map((blogItem, index) => {
          const firstItem = index === 0 && true
          return (
            <div
              key={blogItem.id}
              className={firstItem ? "col-12" : "col-12 col-md-6 col-lg-4"}
            >
              <BlogItem data={blogItem} firstItem={firstItem} />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default BlogList
