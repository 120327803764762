import React from "react"
import styled from "styled-components"
import vars from "../../styles/vars"

const TwoColText = ({ data }) => {
  const {
    mod__text_2_col_left_title: leftTitle,
    mod__text_2_col_left_content: leftContent,
    mod__text_2_col_right_title: rightTitle,
    mod__text_2_col_right_content: rightContent,
  } = data

  return (
    <section className="container mb-4">
      <div className="row">
        <div className="col-12 col-sm-6">
          {leftTitle && <StyledTitle>{leftTitle}</StyledTitle>}
          {leftContent && (
            <div dangerouslySetInnerHTML={{ __html: leftContent }} />
          )}
        </div>
        <div className="col-12 col-sm-6">
          {rightTitle && <StyledTitle>{rightTitle}</StyledTitle>}
          {rightContent && (
            <div dangerouslySetInnerHTML={{ __html: rightContent }} />
          )}
        </div>
      </div>
    </section>
  )
}

export default TwoColText

const StyledTitle = styled.h2`
  margin-top: 0;
  margin-bottom: 1rem;
  color: ${vars.primaryColour};
`
