import React from "react"
import styled from "styled-components"
import vars from "../../styles/vars"

const FullWidthText = ({ title, content }) => {
  return (
    <div className="container mb-4">
      <div className="row">
        <div className="col">
          {title && <StyledTitle>{title}</StyledTitle>}
          {content && (
            <StyledSection dangerouslySetInnerHTML={{ __html: content }} />
          )}
        </div>
      </div>
    </div>
  )
}

export default FullWidthText

const StyledTitle = styled.h2`
  margin: 0;
  color: ${vars.primaryColour};
`

const StyledSection = styled.section`
  h1,
  h2,
  h3 {
    margin-top: 0;
    margin-bottom: 1rem;
  }
`
