import React from "react"
import styled from "styled-components"
import { motion } from "framer-motion"
import vars from "../../styles/vars"
import Button from "../common/button"
import { convertToInternalUrl } from "../../utils/global-util"
import { fadeInUp, stagger } from "../../animations/global-animations"

const TextWithImage = ({ data }) => {
  const {
    mod__text_with_image_img: image,
    mod__text_with_image_title: title,
    mod__text_with_image_content: content,
    mod__text_with_image_position: imgPosition,
    mod__text_with_image_offset_top: offsetTop,
    mod__text_with_image_link: link,
  } = data

  const imageIsRight = imgPosition === "2" ? true : false

  const imageOrderClass = imageIsRight
    ? "col-12 col-lg-6 order-lg-12 img-position-right"
    : "col-12 col-lg-6 order-lg-1 img-position-left"

  // If no image has been set then return null, the whole content for this section wont't be displayed, this component requires an image to be displayed.
  if (!image) return null

  return (
    <StyledTextWithImage
      key={data.id}
      className="container"
      offsetTop={offsetTop}
      imageIsRight={imageIsRight}
      variants={stagger}
    >
      <div key={data.id} className="row no-gutters">
        <div className="col-12 col-lg-6 order-lg-2">
          <StyledContent
            key={image.wordpress_id + "-content"}
            variants={fadeInUp}
            offsetTop={offsetTop}
            imageIsRight={imageIsRight}
          >
            <motion.div variants={stagger}>
              {title && <motion.h2 variants={fadeInUp}>{title}</motion.h2>}
              {content && (
                <motion.div
                  variants={fadeInUp}
                  dangerouslySetInnerHTML={{ __html: content }}
                />
              )}
              {link.url && (
                <motion.div variants={fadeInUp}>
                  <Button to={convertToInternalUrl(link.url)}>
                    {link.title ? link.title : "Find Out More"}
                  </Button>
                </motion.div>
              )}
            </motion.div>
          </StyledContent>
        </div>
        <div className={imageOrderClass}>
          {image && (
            <motion.div
              key={image.wordpress_id}
              variants={fadeInUp}
              className="text-with-img"
              style={{
                background:
                  "url(" + image.sizes.large + ") no-repeat center center",
                backgroundSize: "cover",
              }}
            ></motion.div>
          )}
        </div>
      </div>
    </StyledTextWithImage>
  )
}

export default TextWithImage

const StyledTextWithImage = styled(motion.div)`
  margin-top: ${props => (props.offsetTop ? "-150px" : 0)};
  padding-bottom: ${props => (props.offsetTop ? "80px" : "40px")};

  @media (max-width: ${vars.screen_md_max}) {
    padding-bottom: 0;
  }

  .text-with-img {
    width: ${props => (props.offsetTop ? "calc(100% + 30px)" : "100%")};
    position: relative;
    z-index: 10;
    height: 100%;

    @media (max-width: ${vars.screen_md_max}) {
      width: 100%;
    }
  }

  .img-position-right {
    .text-with-img {
      bottom: ${props => (props.offsetTop ? "-40px" : 0)};
      left: ${props => (props.offsetTop ? "-30px" : 0)};
      @media (max-width: ${vars.screen_md_max}) {
        left: 0;
        bottom: 0;
      }
    }
  }

  .img-position-left {
    .text-with-img {
      bottom: ${props => (props.offsetTop ? "-40px" : 0)};
      @media (max-width: ${vars.screen_md_max}) {
        bottom: 0;
      }
    }
  }
`
const StyledContent = styled(motion.div)`
  min-height: 400px;
  background-color: ${props => (props.offsetTop ? vars.white : "transparent")};
  padding: ${props =>
    (props.imageIsRight && props.offsetTop && "35px 65px 10px 30px") ||
    (!props.imageIsRight && props.offsetTop && "35px 30px 10px 65px") ||
    (!props.imageIsRight && !props.offsetTop && "25px 25px 10px 30px") ||
    "25px 25px 10px 0"};

  @media (max-width: ${vars.screen_md_max}) {
    padding: ${props => (props.offsetTop ? "20px 20px 0 20px" : 0)};
    min-height: auto;
    margin-bottom: 2rem;
  }

  h2 {
    color: ${vars.primaryColour};
    margin-top: 0;
  }
`
