import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/common/seo"
import FullWidthText from "../components/page/full-width-text.jsx"
import FullWidthImage from "../components/page/full-width-image.jsx"
import ImageSlider from "../components/page/image-slider.jsx"
import TwoColText from "../components/page/two-col-text.jsx"
import TextWithImage from "../components/page/text-with-image.jsx"
import ContactForm from "../components/page/contact-form.jsx"
import HeroBanner from "../components/page/hero-banner.jsx"
import FeaturedContentBlock from "../components/page/featured-content-block.jsx"
import StatisticPromoBlocks from "../components/page/statistic-promo-blocks"
import PromoBlocks from "../components/page/promo-blocks"
import BlogList from "../components/ui/blog-list"
import Header from "../components/ui/header"

const PageTemplate = ({ data, location, pageContext }) => {
  const { wordpressPage: page } = data

  // TODO: Look at better ways to handle the below.
  const renderComponents =
    page.acf.modules_page !== null && page.acf.modules_page.length > 0
      ? true
      : false

  // TODO: Is there a better way to get moudles using object destructuring and default values?
  let modules = []
  if (page.acf.modules_page) modules = page.acf.modules_page

  const heroBanner = modules.filter(
    x => x.__typename === "WordPressAcf_mod__hero_banner"
  )
  //--

  let padBottomHeroBanner = modules.some(
    x =>
      x.__typename === "WordPressAcf_mod__text_with_image" &&
      x.mod__text_with_image_offset_top === true
  )

  if (page.slug === "blog") padBottomHeroBanner = true

  return (
    <>
      <Header float={false} />
      <SEO yoast={page.yoast_meta} />
      <HeroBanner
        title={page.title}
        content={page.content}
        data={heroBanner}
        padBottomHeroBanner={padBottomHeroBanner}
        currentPageSlug={page.slug}
        breadcrumb={pageContext.breadcrumb}
      />

      {/* TODO: Is there a better way to determine were on the blog page and to show the list of blog items. If the slug changed, for example to be 'news'
      the posts would no longer show until the code is manually updated. The same applies for the hero banner on the homepage. */}
      {page.slug === "blog" && <BlogList />}

      {renderComponents &&
        page.acf.modules_page.map(item => {
          switch (item.__typename) {
            case "WordPressAcf_mod__full_width_text":
              return (
                <FullWidthText
                  key={item.id}
                  title={item.mod__full_width_text_title}
                  content={item.mod__full_width_text_content}
                />
              )
            case "WordPressAcf_mod__full_width_image":
              return (
                <FullWidthImage
                  key={item.id}
                  src={item.mod__full_width_image_img.url}
                  alt={item.mod__full_width_image_img.alt}
                />
              )
            case "WordPressAcf_mod__image_slider":
              return (
                <ImageSlider
                  key={item.id}
                  items={item.mod__image_slider_images}
                />
              )
            case "WordPressAcf_mod__text_2_col":
              return <TwoColText key={item.id} data={item} />
            case "WordPressAcf_mod__text_with_image":
              return <TextWithImage key={item.id} data={item} />
            case "WordPressAcf_mod__contact_form":
              return <ContactForm key={item.id} data={item} />
            case "WordPressAcf_mod__featured_content_block":
              return <FeaturedContentBlock key={item.id} data={item} />
            case "WordPressAcf_mod__statistic_promo_blocks":
              return <StatisticPromoBlocks key={item.id} data={item} />
            case "WordPressAcf_mod__promo_blocks":
              return <PromoBlocks key={item.id} data={item} />
            default:
              return null
          }
        })}
    </>
  )
}

export default PageTemplate

export const query = graphql`
  query($id: String!) {
    wordpressPage(id: { eq: $id }) {
      id
      title
      excerpt
      content
      slug
      acf {
        modules_page {
          ... on WordPressAcf_mod__full_width_text {
            __typename
            id
            mod__full_width_text_content
            mod__full_width_text_title
          }
          ... on WordPressAcf_mod__contact_form {
            __typename
            id
            mod__contact_form_show
          }
          ... on WordPressAcf_mod__text_with_image {
            __typename
            id
            mod__text_with_image_content
            mod__text_with_image_position
            mod__text_with_image_title
            mod__text_with_image_offset_top
            mod__text_with_image_img {
              alt
              sizes {
                large
              }
              wordpress_id
            }
            mod__text_with_image_link {
              post
              target
              title
              type
              url
            }
          }
          ... on WordPressAcf_mod__featured_content_block {
            __typename
            id
            mod__featured_content_block_button {
              post
              target
              title
              type
              url
            }
            mod__featured_content_block_content
            mod__featured_content_block_title
            mod__featured_content_block_banner
            mod__featured_content_block_logo
          }
          ... on WordPressAcf_mod__statistic_promo_blocks {
            __typename
            id
            mod__statistic_promo_blocks_promo {
              mod__statistic_promo_content
            }
          }
          ... on WordPressAcf_mod__promo_blocks {
            __typename
            id
            mod__promo_blocks_promo {
              mod__promo_blocks_content_text
              mod__promo_blocks_icon_image
              mod__promo_blocks_title
              mod__promo_blocks_img
              mod__promo_blocks_icon_link {
                url
                title
              }
            }
          }
          ... on WordPressAcf_mod__hero_banner {
            __typename
            id
            mod__hero_banner_image
          }
        }
      }
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
        yoast_wpseo_canonical
        yoast_wpseo_facebook_title
        yoast_wpseo_facebook_description
        yoast_wpseo_facebook_type
        yoast_wpseo_facebook_image
        yoast_wpseo_twitter_title
        yoast_wpseo_twitter_description
        yoast_wpseo_twitter_image
        yoast_wpseo_social_url
        yoast_wpseo_company_or_person
        yoast_wpseo_person_name
        yoast_wpseo_company_name
        yoast_wpseo_company_logo
        yoast_wpseo_website_name
      }
    }
  }
`
// NOTE: Removed the below from graphql query, if we leave it in and remove all h1 moudles from a page we get an unknown type error
// How should we handle this? We need to be able to define our types before hand if possible. If someone adds a h1 module to a page
// the h1 wont show until the below code is added to the query again.

// ... on WordPressAcf_mod__h1 {
//   __typename
//   id
//   mod__h1
//   mod__h1_position
// }

// ... on WordPressAcf_mod__full_width_image {
//   __typename
//   id
//   mod__full_width_image_img {
//     sizes {
//       large
//     }
//     alt
//     url
//   }
// }

// ... on WordPressAcf_mod__text_2_col {
//   __typename
//   id
//   mod__text_2_col_left_content
//   mod__text_2_col_left_title
//   mod__text_2_col_right_content
//   mod__text_2_col_right_title
// }
