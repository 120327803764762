import React from "react"
//import Slider from "react-slick"
// NOTE: Slider functionality no implemented yet, no sure if we require this, left in for reference.
// To use this component 'react-slick' will need to be installed through npm.
const ImageSlider = ({ items }) => {
  // const settings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   autoplay: true,
  // }
  return (
    <div></div>
    // <Slider {...settings}>
    //   {items.map(item => (
    //     <div key={item.mod__image_slider_img.wordpress_id}>
    //       <img
    //         src={item.mod__image_slider_img.sizes.large}
    //         alt={item.mod__image_slider_img.alt}
    //       />
    //     </div>
    //   ))}
    // </Slider>
  )
}

export default ImageSlider
