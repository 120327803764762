import React from "react"
import { Link } from "gatsby"
import unslugify from "unslugify"
import _ from "lodash"
import styled from "styled-components"
import vars from "../../styles/vars"

function Breadcrumb({ data, theme = "light", type = "page" }) {
  const { crumbs } = data || []

  if (crumbs?.length > 1) {
    return (
      <StyledBreadcrumbs theme={theme}>
        {crumbs.map((crumb, index) => (
          <span key={`crumb-${crumb.crumbLabel}`}>
            <Link to={crumb.pathname}>{unslugify(crumb.crumbLabel)}</Link>
            {index + 1 !== crumbs.length && " > "}
          </span>
        ))}
      </StyledBreadcrumbs>
    )
  }
  return null
}

export default Breadcrumb

const StyledBreadcrumbs = styled.div`
  a {
    color: ${props => (props.theme === "dark" ? vars.black : vars.white)};
    position: relative;

    &:after {
      content: "";
      width: 0;
      background: ${props =>
        props.theme === "dark" ? vars.black : vars.white};
      height: 1px;
      position: absolute;
      bottom: -3px;
      left: 50%;
      transition: ease-in-out 200ms;
    }

    &:hover {
      &:after {
        left: 0;
        width: 100%;
      }
    }
  }
`
