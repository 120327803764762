import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { toast } from "react-toastify"
import * as yup from "yup"
import { apiUrl } from "../../../config.json"
import Input from "../common/input"
import Label from "../common/label"
import Button from "../common/button"
import { navigate } from "gatsby"

const FormSchema = yup.object().shape({
  name: yup
    .string()
    .max(100)
    .required(),
  email: yup
    .string()
    .max(100)
    .email()
    .required(),
  message: yup
    .string()
    .max(1000)
    .required(),
})

const ContactForm = () => {
  const [isLoading, setIsLoading] = useState(false)
  const { register, handleSubmit, errors } = useForm({
    validationSchema: FormSchema,
    submitFocusError: false,
  })

  const onSubmit = async data => {
    setIsLoading(true)

    try {
      const response = await fetch(`${apiUrl}/bin/contact.php`, {
        method: "POST",
        body: JSON.stringify(data),
      })

      const responseData = await response.json()

      if (response) {
        setIsLoading(false)
        if (responseData === 1) navigate("/contact-us/thank-you")
        else toast.error("Something went wrong, please try again later.")
      }
    } catch (error) {
      console.error(error.message)
      toast.error("Failed to send request, please try again later.")
      setIsLoading(false)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-6 mb-3">
            <Label name="name" label="Name" />
            <Input name="name" register={register} error={errors.name} />
          </div>
          <div className="col-12 col-sm-6 mb-3">
            <Label name="email" label="Email" />
            <Input name="email" register={register} error={errors.email} />
          </div>
          <div className="col-12 mb-3">
            <Label name="message" label="Message" />
            <Input
              name="message"
              register={register}
              error={errors.message}
              as="textarea"
            />
          </div>
          <div className="col-12 text-right">
            <Button type="submit" isLoading={isLoading}>
              Submit!
            </Button>
          </div>
        </div>
      </div>
    </form>
  )
}

export default ContactForm
