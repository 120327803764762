import React from "react"
import Button from "./button"
import styled from "styled-components"
import vars from "../../styles/vars"
import { convertToInternalUrl } from "../../utils/global-util"

const BlogItem = props => {
  const { data, firstItem } = props

  // TODO: Look at better ways to handle the below.
  const renderComponents =
    data.acf.modules_post !== null && data.acf.modules_post.length > 0
      ? true
      : false

  return (
    <StyledBlogItem className="row mb-4" firstItem={firstItem}>
      <div
        className={
          firstItem ? "col-12 col-lg-6 mb-3 pr-3 pr-md-0" : "col-12 mb-3"
        }
      >
        {renderComponents &&
          data.acf.modules_post.map(item => {
            switch (item.__typename) {
              case "WordPressAcf_mod__hero_banner":
                return (
                  <img
                    key={`${data.id}-${item.__typename}`}
                    src={item.mod__hero_banner_image}
                    alt={data.title}
                    className="img-fluid"
                  />
                )
              default:
                return null
            }
          })}
        {!renderComponents && (
          <img
            src="https://placehold.it/600x400?text=Image%20Coming%20Soon"
            alt={data.title}
            className="img-fluid"
          />
        )}
      </div>
      <BlogDetails
        className={firstItem ? "col-12 col-lg-6 mb-3" : "col-12 mb-3"}
        firstItem={firstItem}
      >
        <BlogTitle className="mt-0 mb-1">{data.title}</BlogTitle>
        <strong>{data.date}</strong>
        <div dangerouslySetInnerHTML={{ __html: data.excerpt }} />
        <Button to={convertToInternalUrl(data.path)}>
          {firstItem ? "Read Full Story" : "Read More"}
        </Button>
      </BlogDetails>
    </StyledBlogItem>
  )
}

export default BlogItem

const BlogTitle = styled.h2`
  color: ${vars.primaryColour};
`

const StyledBlogItem = styled.div`
  margin-top: ${props => (props.firstItem ? "-150px" : 0)};
`

const BlogDetails = styled.div`
  background: ${props => (props.firstItem ? vars.white : "none")};
  @media (min-width: ${vars.screen_lg_min}) {
    ${props => props.firstItem && "padding: 2rem 2.5rem 0 2.5rem;"}
  }
`
