import React from "react"

const FullWidthImage = ({ src, alt }) => {
  return (
    <section className="container mb-4">
      <div className="row">
        <div className="col">
          <img src={src} alt={alt} className="img-fluid" loading="lazy" />
        </div>
      </div>
    </section>
  )
}

export default FullWidthImage
