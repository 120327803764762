import React from "react"
import styled from "styled-components"
import vars from "../../styles/vars"
import { convertToInternalUrl } from "../../utils/global-util"
import Button from "../common/button"

const FeaturedContentBlock = props => {
  const {
    mod__featured_content_block_title: title,
    mod__featured_content_block_content: content,
    mod__featured_content_block_button: button,
    mod__featured_content_block_banner: banner,
    mod__featured_content_block_logo: logo,
  } = props.data
  return (
    <div className="container mb-4">
      <div className="row">
        <div className="col">
          <StyledFeaturedContent>
            <div className="row">
              <div className="col">
                <Title>{title}</Title>
                {/* Wordpress returns the html with &nbsp; for each space, this means the content is treat as 
            one long word and the content doesn't naturally break to the container width. */}
                <div
                  className="content-block"
                  dangerouslySetInnerHTML={{
                    __html: content.replace("&nbsp;", " "),
                  }}
                />
                {button?.url && (
                  <Button
                    to={convertToInternalUrl(button.url)}
                    className="mt-2"
                  >
                    {button.title ? button.title : "Find Out More"}
                  </Button>
                )}
              </div>
              {logo && (
                <div className="col-auto d-none d-sm-block">
                  <img src={logo} alt="Featured Content Logo" loading="lazy" />
                </div>
              )}
            </div>
          </StyledFeaturedContent>
          {banner && (
            <img
              src={banner}
              className="w-100"
              alt="Featured Content Banner"
              loading="lazy"
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default FeaturedContentBlock

const StyledFeaturedContent = styled.section`
  background: ${vars.black};
  color: ${vars.white};
  padding: 1.5rem;
  font-size: 1.2rem;
  .content-block {
    a {
      color: ${vars.secondaryColour};
    }
  }
`

const Title = styled.h2`
  margin: 0 0 1rem 0;
  color: ${vars.primaryColour};
  font-size: 3rem;

  @media (max-width: ${vars.screen_sm_max}) {
    font-size: 1.8rem;
  }
`
