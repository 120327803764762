import React from "react"
import styled from "styled-components"
import vars from "../../styles/vars"

const Input = ({ register, name, error, as = "input", ...rest }) => {
  return (
    <InputGroup>
      <StyledInput as={as} name={name} id={name} ref={register} {...rest} />
      {error && (
        <ErrorMessage>{error.message ? error.message : "Error"}</ErrorMessage>
      )}
    </InputGroup>
  )
}

export default Input

const InputGroup = styled.div`
  position: relative;
  margin-bottom: 30px;

  textarea {
    min-height: 200px;
  }
`

const StyledInput = styled.input`
  display: block;
  background-color: ${vars.lightGrey};
  border: none;
  width: 100%;
  font-size: 16px;
  padding: 12px 15px;
  border-bottom: 1px solid ${vars.grey};
  outline: none;
  &:focus,
  &:hover {
    border-bottom: 1px solid ${vars.primaryColour};
  }
`
const ErrorMessage = styled.div`
  color: ${vars.crimson};
  font-size: 12px;
  position: absolute;
  bottom: -20px;
`
