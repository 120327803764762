import React from "react"
import styled from "styled-components"

const Label = ({ name, label }) => {
  return <StyledLabel htmlFor={name}>{label}</StyledLabel>
}

export default Label

const StyledLabel = styled.label`
  margin-bottom: 10px;
  display: block;
`
