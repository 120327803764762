import React from "react"
import Button from "../common/button"
import styled from "styled-components"
import vars from "../../styles/vars"
import { convertToInternalUrl } from "../../utils/global-util"

const PromoBlocks = ({ data }) => {
  const { mod__promo_blocks_promo: promos } = data

  return (
    <div className="container">
      <div className="row">
        {promos.map(item => {
          const {
            mod__promo_blocks_title: title,
            mod__promo_blocks_content_text: content,
            mod__promo_blocks_img: backgroundImage,
            mod__promo_blocks_icon_image: icon,
            mod__promo_blocks_icon_link: link,
          } = item

          return (
            <div
              className="col-12 col-lg-6 text-center mb-4"
              key={"promo-block" + item.mod__promo_blocks_title}
            >
              <StyledPromoBlock backgroundImage={backgroundImage}>
                {icon && <img src={icon} alt={title} loading="lazy" />}
                {title && <h2>{title}</h2>}
                {content && <p>{content}</p>}
                {link.url && (
                  <Button to={convertToInternalUrl(link.url)}>
                    {link.title}
                  </Button>
                )}
              </StyledPromoBlock>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default PromoBlocks

const StyledPromoBlock = styled.section`
  width: 100%;
  padding: 3rem 2rem;
  background: #eee;
  height: 100%;
  background: ${"linear-gradient(270deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.7) 100%),"}
    url(${props => props.backgroundImage}) no-repeat top center;
  background-size: cover;
  color: ${vars.white};
`
