import React from "react"
import styled from "styled-components"
import vars from "../../styles/vars"

const StatisticPromoBlocks = ({ data }) => {
  const { mod__statistic_promo_blocks_promo: promos } = data
  return (
    promos && (
      <div className="container mb-4">
        <div className="row">
          <div className="col-12">
            <StyledStatisticContainer className="p-3">
              <div className="row justify-content-center text-center text-lg-left">
                {promos.map((promo, index) => (
                  <div
                    className={
                      promos.length > 1
                        ? "col-12 col-lg-6 mb-lg-0"
                        : "col-12 col-md-auto"
                    }
                    key={data.id + index}
                  >
                    <div className={index === 0 ? "row mb-3" : "row"}>
                      <div className="col-auto d-none d-lg-block">
                        <StyledCircle />
                      </div>
                      <div
                        className="col"
                        dangerouslySetInnerHTML={{
                          __html: promo.mod__statistic_promo_content,
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </StyledStatisticContainer>
          </div>
        </div>
      </div>
    )
  )
}

export default StatisticPromoBlocks

const StyledStatisticContainer = styled.section`
  background: ${vars.black};
  color: ${vars.white};
  font-size: 1.6rem;
  line-height: 2.2rem;
  font-weight: 700;

  p {
    margin: 0;
    line-height: 2.2rem;
  }
`

const StyledCircle = styled.div`
  width: 70px;
  height: 70px;
  border: 2px solid ${vars.white};
  border-radius: 50%;
  position: relative;
  :after {
    content: "";
    width: 30px;
    height: 30px;
    border: 2px solid ${vars.grey};
    border-radius: 50%;
    position: absolute;
    left: calc(50% - 15px);
    top: calc(50% - 15px);
  }
`
