import transitions from "./transitions"

export default {
  initial: { opacity: 0, x: "-100%" },
  animate: {
    opacity: 1,
    y: 0,
    x: 0,
    transition: {
      duration: 1,
      when: "beforeChildren",
      ease: transitions.easeSmooth,
    },
  },
  exit: {
    opacity: 0,
    x: "100%",
    transition: {
      duration: 1,
      when: "afterChildren",
      ease: transitions.easeSmooth,
    },
  },
}
